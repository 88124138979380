import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="8eybkzsbxg"
      title="Unlock Next Generation Building Management with Rhombus Access Control"
      description="From convenient wave-to-unlock technology to smart notifications for unauthorized entry, Rhombus is bringing access control into the future. Explore an all-in-one physical security solution for your business."
    />
  );
}
